@tailwind base;
@tailwind components;
@tailwind utilities;

.heroBackground {
    background-image: url("./assets/medicalHerBakcgroundImg.png");
    height: 600px;
    /* Set this to the actual height of your image */
    background-size: cover;
    /* Ensure the image covers the entire div */
    background-position: center;
    /* Center the background image */
    opacity: 1;
    background-origin: content-box;
}

.slick-dots li button:before {
    color: white !important;
    font-size: 10px !important;
    /* Change dot color to white */
    opacity: 0.5;
    /* Set the opacity for inactive dots */
}

.slick-dots li.slick-active button {
    opacity: 1;
    /* Full opacity for the active dot */
}